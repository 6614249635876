import {io} from "socket.io-client";
import {baseUrl, suffix} from './GetURL';

let socketIO;
const socketInstance = {
    connectionSocket: function(toVerify){
        socketIO = io(baseUrl+(suffix === ":42000" ? suffix : ""), {
            query:"toVerify="+toVerify,
            path:"/sekuroom/socket.io",
        });
        socketIO.on("connect", () => {
            sessionStorage.setItem("socket_id_session",socketIO.id)
        });
        socketIO.on("connectResult", (args) => {
            if(args.status === "Forbidden"){
                socketIO.disconnect()
                console.log("Forbidden : Socket déconnecté.")
            }
        });
        return socketIO
    }
}

export default socketInstance;
