import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import Swal from "sweetalert2";
import "./styleSK.css"
import {isMobile} from "react-device-detect";
import SharedFooterSK from "./components/SharedFooterSK";
import SharedInscriptionComponent from "./SharedInscriptionComponent";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InputIcon from "@mui/icons-material/Input";
import {sha256} from "js-sha256";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import {AccountCircle} from "@mui/icons-material";
import {urlAxios} from '../Components/GetURL';

let jwtDecoded;

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


class InvitationDossierSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            displayInscription:true,
            folderName:"",
            pseudoG:"",
            displayChoice:true
        };
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.resizePage)
    }


    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
        let code = new URLSearchParams(window.location.search).get("code"),
            idToken = new URLSearchParams(window.location.search).get("idToken");
        if(!!code && !!idToken){
            this.setState({code, idToken},()=>{
                Axios.post(urlAxios+"getInvitationSK",{code, idToken}).then(response=>{
                    if(response.data.resultat == "done"){
                        this.setState({folderName:response.data.folderName, pseudoG:response.data.pseudoG})
                    }else{
                        setTimeout(()=>{
                            this.props.navigate("/Sekuroom/Accueil")
                        },500)
                        return
                    }
                })
            })
        }else{
            setTimeout(()=>{
                this.props.navigate("/Sekuroom/Accueil")
            },500)
            return
        }
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    handleConnexion(e){
        this.setState({showPassword:false, displayMenuMobile:false})
        if(e !== "fromInsc"){
            e.preventDefault()
        }
        let empreinte = sha256((this.state.champsIdentifiant.trim()).concat(this.state.champsPassword.trim()));
        Axios.post(urlAxios+"connexionSK",{
            empreinte:empreinte,
            code:this.state.code,
            idToken:this.state.idToken
        },{headers:{Authorization:"Bearer " + sessionStorage.getItem("SK_jwt")}}).then(response=>{
            if(response.data.jwt){
                sessionStorage.setItem("SK_jwt",response.data.jwt)
                this.setState({
                    jwt:response.data.jwt,
                    jwtDecoded:jwt_decode(response.data.jwt)
                })
            }
            if (response.data.resultat === "Connecté"){
                global.emitter.emit("onRouteChangedSK", {
                    jwt: this.state.jwt,
                })
                Toast.fire({
                    icon: 'success',
                    title: 'Bienvenue '+this.state.champsIdentifiant + ', vous allez être redirigé bientôt.'
                })
                setTimeout(()=>{
                    this.setState({displayConnexion:false},()=>{
                        this.props.navigate("/Sekuroom/MesDossiers")
                    })
                },1500)
            }else{
                this.setState({displayConnexion:false})
                Swal.fire({
                    title: 'Une erreur est survenue.',
                    text: response.data.resultat,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                }).then(()=>{this.setState({displayConnexion:true,readyToClick:true})})
            }
        }).catch(()=>{
            this.setState({displayConnexion:false},()=> {
                Swal.fire({
                    title: 'Erreur!',
                    text: "Serveur injoignable.",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        })
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", overflow:"hidden", justifyContent:"center"}}>
                        <div className={"flexCenter"} style={{width:this.state.showBrowserView ?"65%":"100%",  flexDirection:"column", alignItems:"flex-start", marginLeft:this.state.showBrowserView && 90}}>
                            <h1 className={"SKFont"} style={{color:"#000", fontSize:40, margin:1}}>Invitation au dossier : <span style={{fontStyle:"italic", fontWeight:600}}>{this.state.folderName}</span></h1>
                            <div className={"flexCenter"} style={{width:"100%",  flexDirection:"column", alignItems:"flex-start"}}>
                                <p style={{margin:0, fontSize:20}}><span style={{fontStyle:"italic", fontWeight:600}}>{this.state.pseudoG}</span> vous a invité a rejoindre le dossier Sekuroom <span style={{fontStyle:"italic", fontWeight:600}}>{this.state.folderName}</span></p>
                                <p style={{margin:0, fontSize:20, marginBottom:35}}>Pour accepter, inscrivez-vous ou connectez vous ci-dessous</p>
                                {this.state.displayChoice ? (
                                    <React.Fragment>
                                        <div className={"flexCenter"} style={{width:"100%",  flexDirection:"column", alignItems:"flex-start"}}>
                                            <button onClick={()=>{this.setState({displayInscription:true, displayChoice:false})}} className={"buttonSK flexCenter"} style={{width:"100%", maxWidth:600, marginBottom:20}}>Je n'ai pas de compte <InputIcon style={{marginLeft:10}}/></button>
                                            <button onClick={()=>{this.setState({displayInscription:false, displayChoice:false})}} className={"buttonSK flexCenter"} style={{width:"100%", maxWidth:600}}>J'ai déjà un compte <AccountCircle style={{marginLeft:10}}/></button>
                                        </div>
                                    </React.Fragment>
                                ):(
                                    <React.Fragment>
                                        {this.state.displayInscription ? (
                                            <div className={"flexCenter"} style={{width:"100%",  flexDirection:"column", alignItems:"flex-start"}}>
                                                <SharedInscriptionComponent code={this.state.code} idToken={this.state.idToken} typeInvitation={true}/>
                                                <p onClick={()=>{this.setState({displayInscription:false})}} style={{cursor:"pointer", color:"#0d57fa", textDecoration:"underline"}}>J'ai déjà un compte, me connecter</p>
                                            </div>
                                        ):(
                                            <div className={"flexCenter"} style={{width:"100%", justifyContent:"flex-start"}}>
                                                <div className={"flexCenter"} style={{width:"100%",  flexDirection:"column", alignItems:"flex-start"}}>
                                                    <form style={{width:"75%", maxWidth:600}} autoComplete="off" onSubmit={(e)=>{this.handleConnexion(e)}}>
                                                        <div style={{position:"relative", width:"100%"}}>
                                                            <input autoComplete={"off"} name={"champsIdentifiant"} required className={"champsTexteSK SKFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"text"} placeholder={"Identifiant"}/>
                                                        </div>
                                                        <div style={{position:"relative", width:"100%"}}>
                                                            <input autoComplete={"new-password"} name={"champsPassword"} required className={"champsTexteSK SKFont"} value={this.state.value} onChange={(e)=>{this.handleChange(e)}} style={{border:"1px solid #232323FF", color:"#232323FF", position:"relative"}} type={this.state.showPassword ? "text":"password"} placeholder={"Clef unique"}/>
                                                            <div style={{position:"absolute", width:"fit-content", right:10, top:24}}>
                                                                {this.state.showPassword ? (
                                                                    <VisibilityIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:false})}}/>
                                                                ):(
                                                                    <VisibilityOffIcon style={{cursor:"pointer"}} onClick={()=>{this.setState({showPassword:true})}}/>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className={"flexCenter"} style={{justifyContent:"flex-start"}}>
                                                            <button disabled={!this.state.champsPassword || ! this.state.champsIdentifiant} type={"submit"} style={{width:"fit-content", marginTop:15}} className={"buttonSK"}><h2 className={"SKFont"} style={{margin:0, fontWeight:600}}>Se connecter et accepter l'invitation</h2></button>
                                                        </div>
                                                    </form>
                                                    <p onClick={()=>{this.setState({displayInscription:true})}} style={{cursor:"pointer", color:"#0d57fa", textDecoration:"underline"}}>Je n'ai pas encore de compte, m'inscrire</p>
                                                </div>

                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        {this.state.showBrowserView && (
                            <div className={"flexCenter"} style={{width:"35%", flexDirection:"column", alignItems:"flex-start"}}>
                                <img style={{width:"100%"}} src={require("../assets/Mockup-Sekuroom.png")} alt="Illustration laptop"/>
                            </div>
                        )}
                    </div>
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}

export default withRouter(InvitationDossierSK)