import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';

import Swal from "sweetalert2";
import "./styleSK.css"
import {isMobile} from "react-device-detect";
import SharedFooterSK from "./components/SharedFooterSK";
import SharedInscriptionComponent from "./SharedInscriptionComponent";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class InscriptionSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
        };
    }

    componentWillUnmount(){

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
    }
    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"center"}}>
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
                            <div className={"flexCenter"} style={{width:"65%", marginLeft:80, flexDirection:"column", alignItems:"flex-start"}}>
                                <h1 className={"SKFont flexCenter"} style={{color:"#000", fontSize:40}}>
                                    Inscrivez-vous à <img style={{height:50, margin:15}} src={require("../assets/LogoSekuroom.png")} alt={"LogoSekuroom"}/> <span style={{color:"#64A7DBFF"}}>Seku</span>
                                    <span style={{color:"#F19028FF"}}>room</span>
                                </h1>
                                <SharedInscriptionComponent/>
                            </div>
                            {this.state.showBrowserView && (
                                <div className={"flexCenter"} style={{width:"35%", flexDirection:"column", alignItems:"flex-start"}}>
                                    <img style={{width:"100%"}} src={require("../assets/Mockup-Sekuroom.png")} alt="Illustration laptop"/>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}

export default withRouter(InscriptionSK)