import React, {Component} from 'react';
import {withRouter} from '../shared/NavigationUtils';
import Swal from "sweetalert2";
import "./styleSK.css"
import {isMobile} from "react-device-detect";
import {Info} from "@mui/icons-material";
import SharedFooterSK from "./components/SharedFooterSK";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

class AccueilSK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBrowserView:true,
            eaaot:false
        };
    }

    componentWillUnmount(){

    }

    resizePage = () => {
        if(document.body.offsetWidth <= 950 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 950 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true,displayMenuMobile:false})
        }
    }
    componentDidMount() {
        if(isMobile || document.body.offsetWidth <= 950){
            this.setState({showBrowserView:false})
        }
        window.addEventListener('resize', this.resizePage)
    }

    handleChange(event){
        this.setState({
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    render() { //Affichage sur la page
        return (
            <>
                <div className={"maindiv-frontpage-SK flexCenter"}>
                    <div className={"flexCenter"} style={{height:"calc(100vh - 110px)", marginTop:70, width:"100%", flexDirection:"column", overflow:"hidden", justifyContent:"center"}}>
                        <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", width:"100%"}}>
                            <div className={"flexCenter"} style={{width:this.state.showBrowserView ? "50%": "100%", marginLeft:this.state.showBrowserView && 80, flexDirection:"column", alignItems:this.state.showBrowserView ? "flex-start":"center"}}>
                                <h1 className={"SKFontTitle flexCenter"} style={{color:"#64A7DBFF", fontWeight:"bold", fontSize:this.state.showBrowserView ? 60: "8vw", marginBottom:5}}>
                                    <img style={{height:this.state.showBrowserView ? 90:"8vw", marginRight:20, cursor:"pointer"}} src={require("../assets/LogoSekuroom.png")} alt={"LogoSekuroom"}/>
                                    Seku
                                    <span style={{color:"#F19028FF"}}>room</span>
                                </h1>
                                <h1 className={"SKFont"} style={{color:"#000", fontSize:this.state.showBrowserView ? 40:"5vw", textAlign:!this.state.showBrowserView&&"center"}}>
                                    Stockez. Partagez. Protégez. Sekuroom, la dataroom sécurisée pour vos fichiers
                                </h1>
                                <h2 onClick={()=>{window.open("https://kagesecur.com/sekuroom/", "_blank")}} className={"SKFont flexCenter"} style={{color:"#000",maxWidth:"80%", fontWeight:100, cursor:"pointer", margin:0, fontSize:!this.state.showBrowserView&&"4vw"}}> <Info style={{color:"#000", marginRight:5}} />En savoir plus</h2>
                            </div>
                            {this.state.showBrowserView && (
                                <div className={"flexCenter"} style={{width:"50%", marginLeft:80, flexDirection:"column", alignItems:"flex-start"}}>
                                    <img style={{width:"100%"}} src={require("../assets/Mockup-Sekuroom.png")} alt="Illustration laptop"/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <SharedFooterSK/>
            </>
        );
    }
}

export default withRouter(AccueilSK)